.baseMobileNav {
  background: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  border-top: 1px solid var(--colorGrey200);
  z-index: 1000;
}

.mobileNav {
  @extend .baseMobileNav;
  height: 55px;
}

.mobileNavIOS {
  @extend .baseMobileNav;
  height: 65px;
}

.blocIcon {
  display: table;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 10px 0 0 0;

  .icon {
    font-size: 18px;
  }

  .text {
    margin-top: -8px;
    font-size: 9px;
    font-weight: 500;
    display: block;
  }

  .notificationDot {
    background-color: var(--colorFail);
    color: var(--colorWhite);
    font-size: 11px;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    position: absolute;
    top: 8px;
    margin-left: 13px;
    text-align: center;
  }
}

p {
  margin: unset;
}

.scrollLayerClassName {
  flex-grow: 1;
  display: flex;

  /* Add default background color to avoid bouncing scroll showing the
 page contents from behind the modal. */
  background-color: var(--colorWhite);

  /* Additional styles for the modal window, dimming the background and positioning the modal */
  min-height: 100vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  @media (--viewportMedium) {
    justify-content: center;
    align-items: flex-start;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.4);
    background-image: none;
  }
}
